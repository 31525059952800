export const SEARCH_FIELDS = {
  unique: [
    { key: 'user_id', label: 'User ID', type: 'number' },
    { key: 'loan_id', label: 'Loan ID', type: 'number' },
    { key: 'full_ssn', label: 'Full SSN', type: 'text', placeholder: 'XXX-XX-XXXX' }
  ],
  secondary: [
    { key: 'first_name', label: 'First Name', type: 'text' },
    { key: 'last_name', label: 'Last Name', type: 'text' },
    { key: 'partial_ssn', label: 'Last 4 SSN', type: 'text', placeholder: 'XXXX' },
    { key: 'email', label: 'Email', type: 'email' },
    { key: 'phone_number', label: 'Phone', type: 'tel', placeholder: '(XXX) XXX-XXXX' },
    { key: 'dob', label: 'Date of Birth', type: 'text', placeholder: 'MM/DD/YYYY' },
    { key: 'street', label: 'Street Address', type: 'text' },
    { key: 'city', label: 'City', type: 'text' },
    { key: 'state', label: 'State', type: 'text' },
    { key: 'zip', label: 'ZIP', type: 'text', placeholder: 'XXXXX or XXXXX-XXXX' }
  ]
};