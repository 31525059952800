import React, { Component } from "react";
import ImageUpload from "./ImageUpload";
import Preview from "./Preview";
import ReactHtmlParser from "react-html-parser";
import { domain } from "../../helpers/route_helper";

class BlogPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postText: "<div class='post'>\n</div>",
      url: "",
      title: "",
      description: "",
      tags: [],
      category: "",
      image: "",
      submitting: false,
      path: "",
      live: false,
      allTags: [],
      autosave: false
    };

    this.input = null;
  }

  handleChange = field => e => {
    if (field === "tags") {
      this.setState({
        tags: [...e.target.selectedOptions].map(x => parseInt(x.value))
      });
    } else this.setState({ [field]: e.target.value });
  };

  handleAutoSave = autosave => () => {
    if (autosave && this.state.live) {
      alert(
        "Cannot turn on autosave for live posts. You probably don't want your typos published in real time. :)"
      );
      return;
    }

    this.setState({ autosave });

    if (autosave) {
      this.autosaveTimer = setInterval(() => {
        if (
          !this.state.live &&
          this.state.url.length > 0 &&
          this.state.autosave
        ) {
          this.submitPost();
        }
      }, 600000);
    } else {
      clearInterval(this.autoSaveTimer);
    }
  };

  fetchPost = () => {
    const path = this.state.path;
    window.$.ajax({
      method: "GET",
      url: `${domain}/api/blog/posts`,
      data: { path }
    }).then(payload =>
      this.setState({
        url: payload.url,
        title: payload.meta_title,
        description: payload.meta_description,
        tags: payload.tags,
        category: payload.category,
        image: payload.meta_image,
        postText: payload.html,
        live: payload.live,
        allTags: payload.all_blog_tags,
        autosave: false
      })
    );
  };

  addTag = tag => () => {
    const text = this.state.postText;
    const position = this.input.selectionStart;
    let open, close;
    switch (tag) {
      case "title":
        open = `<h3 class='blog_header'>`;
        close = `</h3>`;
        break;
      case "sectitle":
        open = `<p><b class='sectitle'>`;
        close = `</b></p>`;
        break;
      case "list":
        open = `
        <ul>
          <li>`;
        close = `</li>
        </ul>`;
        break;
      case "image":
        open = `
        <center><img src='`;
        close = `' alt='ALT TEXT' class='constricted_image'/></center>
        `;
        break;
      case "share":
        if (this.state.url.length === 0) {
          alert("SHARE BUTTONS REQUIRE A URL");
          return;
        }
        open = `
        <div class="share_buttons row">
          <a href="http://www.facebook.com/share.php?u=https://www.figloans.com/blog/posts/${this.state.url}" class="share" id="fb_share">
            <div class="fb_div">
              <img src="https://s3.us-west-2.amazonaws.com/figloans-static-images/logos/facebook_f.png" height="30" width="30">
        	  </div>
      	  </a>
        	<a href="https://twitter.com/share?url=https://www.figloans.com/blog/posts/${this.state.url}" target="_blank" class="share" id="twitter_share">
        		<div class="twi_div">
        			<img src="https://twitter.com/favicons/favicon.ico" height="30" width="30">
        		</div>
        	</a>
        </div>
        `;
        close = ``;
        break;
      default:
    }

    const postText =
      text.substring(0, position) + open + close + text.substring(position);
    this.setState({ postText }, () => {
      this.input.focus();
      this.input.selectionStart = position + open.length;
      this.input.selectionEnd = position + open.length;
    });
  };

  submitPost = () => {
    for (let key in this.state) {
      if (
        [
          "submitting",
          "path",
          "live",
          "autosave",
          "category",
          "allTags",
          "tags"
        ].includes(key)
      ) {
        continue;
      } else if (
        !this.state.live &&
        ["title", "description", "image"].includes(key)
      ) {
        continue;
      } else if (this.state[key].length === 0) {
        alert(`POST NEEDS A ${key.toUpperCase()}`);
        return;
      }
    }

    if (this.state.live && !this.state.image.includes(".")) {
      alert("IMAGE FILE NAME MUST INCLUDE EXTENSION");
      return;
    }

    if (this.state.live && !this.state.postText.includes(this.state.image)) {
      alert(
        "Are you sure that's the right image name? I don't see it anywhere in the html!"
      );
      return;
    }

    this.setState({ submitting: true });

    const blog = {
      subject: this.state.title,
      body: this.state.postText,
      image_file_name: this.state.image,
      short: this.state.description,
      url: this.state.url,
      live: this.state.live,
      category: this.state.category,
      blog_tag_ids: this.state.tags
    };

    window.$.ajax({
      method: "POST",
      url: `${domain}/api/blog/create_post`,
      data: { blog }
    }).then(
      () => {
        this.setState({ submitting: false });
        alert(
          blog.live
            ? `IT'S UP AT https://figloans.com/blog/posts/${this.state.url}!`
            : `DRAFT SAVED FOR ${blog.url}`
        );
      },
      () => {
        this.setState({ submitting: false });
        alert("SOMETHING WENT WRONG!");
      }
    );
  };

  render() {
    return (
      <main>
        <div className="blog-post">
          <div className="blog-edit">
            <div className="blog-images">
              <h2>IMAGE UPLOADER</h2>
              <ImageUpload />
            </div>
            <div className="blog-images">
              <h2>LOAD POST TO EDIT</h2>
              <input
                type="text"
                value={this.state.path}
                placeholder="post url path"
                onChange={this.handleChange("path")}
              />
              <button onClick={this.fetchPost}>Load Post</button>
            </div>
            <div className="blog-post-creator">
              <h2>BLOG POST CREATOR</h2>
              <input
                type="text"
                value={this.state.url}
                placeholder="post url path"
                onChange={this.handleChange("url")}
                disabled={this.state.path.length > 0}
              />
              {this.state.url.length > 0 ? (
                <label>
                  <input
                    type="checkbox"
                    value={this.state.autosave}
                    checked={this.state.autosave}
                    onChange={this.handleAutoSave(!this.state.autosave)}
                  />
                  autosave
                </label>
              ) : null}
              <p className="blog-help">
                (typing 'why-ach' will make the post appear at
                figloans.com/blog/posts/why-ach)
              </p>
              <textarea
                onChange={this.handleChange("postText")}
                value={this.state.postText}
                className="blog-text"
                ref={el => (this.input = el)}
              />
              <p className="blog-help">
                (Use the buttons below for quick html tags WHERE THE CURSOR IS)
              </p>
              {["title", "sectitle", "list", "image", "share"].map(tag => (
                <button onClick={this.addTag(tag)}>{tag}</button>
              ))}
            </div>
            <div className="blog-meta-data">
              <h2>SUBMISSION</h2>
              <input
                type="text"
                value={this.state.title}
                placeholder="post title"
                onChange={this.handleChange("title")}
              />
              <textarea
                value={this.state.description}
                placeholder="post description"
                onChange={this.handleChange("description")}
              />
              <p className="blog-help">
                {" "}
                (This description will appear in email snippets)
              </p>
              <input
                type="text"
                value={this.state.image}
                placeholder="main image file name"
                onChange={this.handleChange("image")}
              />
              <p className="blog-help"> (ex: fig_logo.png)</p>
              <select
                value={this.state.category}
                onChange={this.handleChange("category")}
              >
                <option value="Loan Knowledge Center">Loan Knowledge Center</option>
                <option value="Community Resources and News">Community Resources and News</option>
                <option value="Guides, Deals and Hacks">Guides, Deals and Hacks</option>
                <option value="Fig Chronicles">Fig Chronicles</option>
                <option value="Fig's Coverage on Covid">Fig's Coverage on Covid</option>
                <option value="Fig's Coverage on Hurricane Harvey">Fig's Coverage on Hurricane Harvey</option>
              </select>
              <p className="blog-help"> (Loan Knowledge Center, Community Resources and News, Guides, Deals and Hacks, Fig Chronicles, Fig's Coverage on Covid or Fig's Coverage on Hurricane Harvey)</p>
              <select
                className="tag-select"
                multiple
                onChange={this.handleChange("tags")}
              >
                {Object.keys(this.state.allTags)
                  .sort((a, b) =>
                    this.state.allTags[a] < this.state.allTags[b] ? -1 : 1
                  )
                  .map(tag_id => {
                    if (this.state.tags[tag_id]) {
                      return (
                        <option selected value={tag_id}>
                          {this.state.allTags[tag_id]}
                        </option>
                      );
                    }
                    return (
                      <option value={tag_id}>
                        {this.state.allTags[tag_id]}
                      </option>
                    );
                  })}
              </select>
              <p className="blog-help">
                {" "}
                (select all the tags you want on this post)
              </p>

              <label>
                <input
                  type="checkbox"
                  checked={this.state.live}
                  onChange={() => this.setState({ live: !this.state.live })}
                />
                Live
              </label>
              {this.state.submitting ? null : (
                <button onClick={this.submitPost}>Submit Post</button>
              )}
            </div>
          </div>
          <div className="blog-preview">
            <h3>
              <i>PREVIEW</i>
            </h3>
            <Preview
              html={ReactHtmlParser(this.state.postText)}
              open={this.state.preview}
              closeAction={() => this.setState({ preview: false })}
            />
          </div>
        </div>
      </main>
    );
  }
}

export default BlogPost;
