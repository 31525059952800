import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  useGetDisputeDetailsQuery,
  useUpdateDisputeMutation,
  useResolveDisputeMutation,
  useAssignDisputeMutation,
} from "../disputesApi";
import { showFlashMessage } from "../../flashMessage/flashMessageSlice";
import { useDispatch, useSelector } from "react-redux";
import { currentEmployeeId } from "../../session/sessionSlice";
import {
  DISPUTE_STATUS,
  RESPONSE_TYPES,
  getDisputeDisplayFromApi,
  getDisputeApiFromDisplay,
} from "../helpers/disputeHelper";
import { Routes } from "../helpers/routeHelper";
import { formatYearFirstDashDate } from "../../../helpers/date_helper";
import { Tabs, Tab, TabPanel } from './Tabs';
import UserSearchTab from './UserSearchTab';
import DisputeDetailsTab from './DisputeDetailsTab';
import DisputeResolutionTab from './DisputeResolutionTab';
import { 
  stripPhoneFormat, 
  stripSSNFormat 
} from '../../../helpers/input_helper';
import TimeoutContainer from '../../../components/TimeoutContainer';

const TABS = {
  DETAILS: 0,
  USER_SEARCH: 1,
  RESOLUTION: 2
};

const DisputeDetail = ({
  match,
  history,
  currentAssignedDispute,
  onAssign,
  onUnassign,
}) => {
  const { id } = match.params;
  const { data: dispute, error, isLoading } = useGetDisputeDetailsQuery(id);
  const [updateDispute, { isLoading: isUpdating }] = useUpdateDisputeMutation();
  const [resolveDispute] = useResolveDisputeMutation();
  const [assignDispute] = useAssignDisputeMutation();
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedResponseType, setSelectedResponseType] = useState("");
  const [activeTab, setActiveTab] = useState(TABS.DETAILS);
  const [searchState, setSearchState] = useState({
    params: { page: 1 },
    shouldSearch: false
  });
  const [isSearching, setIsSearching] = useState(true);

  const employeeId = useSelector(currentEmployeeId);
  const belongsToEmployee = dispute?.employee_id == employeeId;
  const canEdit = belongsToEmployee && dispute?.status === DISPUTE_STATUS.ASSIGNED;
  const dueDate = formatYearFirstDashDate(dispute?.due_date);

  const resetFormValues = (dispute) => {
    setFormValues({
      user_id: dispute?.user_id || "",
      loan_id: dispute?.loan_id || "",
      first_name: dispute?.first_name || "",
      last_name: dispute?.last_name || "",
      street: dispute?.street || "",
      city: dispute?.city || "",
      state: dispute?.state || "",
      zip: dispute?.zip || "",
      type: getDisputeDisplayFromApi(dispute?.type) || "",
      sent_by_third_party: dispute?.sent_by_third_party || false,
      contains_valid_3pa: dispute?.contains_valid_3pa || false,
    });
    setSelectedResponseType("");
    setHasChanges(false);
  };

  // Reset form and search state when dispute data loads
  useEffect(() => {
    if (dispute) {
      resetFormValues(dispute);
      setSearchState({
        params: { page: 1 },
        shouldSearch: false
      });
      setIsSearching(true);
    }
  }, [dispute]);

  // Update parent when dispute status changes
  useEffect(() => {
    if (dispute) {
      onAssign(dispute.id, dispute.status);
    }
  }, [dispute?.id, dispute?.status]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      onUnassign();
    };
  }, []);

  useEffect(() => {
    // If user is on a restricted tab and canEdit is false, redirect to details
    // This is specifically aimed at navigation immediately after resolution
    if (!canEdit && (activeTab === TABS.USER_SEARCH || activeTab === TABS.RESOLUTION)) {
      setActiveTab(TABS.DETAILS);
    }
  }, [canEdit, activeTab]);

  const submitDisputeUpdate = async () => {
    const updatedDispute = {
      ...formValues,
      phone_number: stripPhoneFormat(formValues.phone_number),
      full_ssn: stripSSNFormat(formValues.full_ssn),
      type: getDisputeApiFromDisplay(formValues.type)
    };

    await updateDispute({
      id: dispute.id,
      dispute: updatedDispute,
    }).unwrap();

    setHasChanges(false);
    dispatch(
      showFlashMessage({
        message: "Dispute information updated successfully",
        type: "success",
        duration: 5,
      })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!canEdit || !hasChanges) return;

    try {
      await submitDisputeUpdate();
    } catch (err) {
      showErrorMessage(err?.data?.message || "Failed to save dispute information");
    }
  };

  const handleChange = (fieldName, value) => {
    setFormValues((prev) => ({
      ...prev,
      [fieldName]: value
    }));
    setHasChanges(true);
  };

  const handleBackToDisputes = async () => {
    if (currentAssignedDispute.status === DISPUTE_STATUS.ASSIGNED) {
      await onUnassign(); // Wait for unassignment to complete
    }
    history.push(Routes.UNRESOLVED);
  };

  const handleResolve = async () => {
    if (!selectedResponseType) {
      showErrorMessage("Please select a response type");
      return;
    }

    try {
      if (hasChanges) {
        await submitDisputeUpdate();
      }

      const responseTypeApi = RESPONSE_TYPES[selectedResponseType].api;
      const result = await resolveDispute({
        id: dispute.id,
        responseType: responseTypeApi,
      }).unwrap();

      dispatch(
        showFlashMessage({
          message: "Dispute resolved successfully",
          type: "success",
          duration: 5,
        })
      );
    } catch (err) {
      err?.data?.errors?.length > 0
        ? err.data.errors.map((error) => {
            showErrorMessage(error || "Failed to resolve dispute", 7);
          })
        : showErrorMessage(err?.data?.message || "Failed to resolve dispute");
    }
  };
  
  /**
   * Handles assigning a new dispute to the current user.
   * Currently, this functionality is only available when viewing a dispute that 
   * isn't currently unresolved/assigned to the current user.
   * If the current dispute IS unresolved and assigned to the current user, it will unassign that dispute first.
   * Then it assigns and navigates to the next available dispute.
   */
  const handleAssignDispute = async () => {
    try {
      // First unassign current dispute if needed
      if (currentAssignedDispute.status === DISPUTE_STATUS.ASSIGNED && dispute.employee_id == employeeId) {
        await onUnassign();
      }

      // Then get the next dispute
      const result = await assignDispute().unwrap();
      if (result.success) {
        history.replace(Routes.getDetailPath(result.dispute_id));
      }
    } catch (err) {
      dispatch(
        showFlashMessage({
          message: err.data?.message || "Failed to assign dispute",
          type: "error",
          duration: 5,
        })
      );
    }
  };

  const showErrorMessage = (err, duration = 5) => {
    dispatch(
      showFlashMessage({
        message: err || "Something went wrong",
        type: "error",
        duration,
      })
    );
  };

  const handleUpdateDisputeInfo = (user) => {
    setFormValues(prev => ({
      ...prev,
      user_id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      street: user.address.street,
      city: user.address.city,
      state: user.address.state,
      zip: user.address.zip
    }));
    setHasChanges(true);
    
    dispatch(showFlashMessage({
      message: 'User information applied to dispute',
      type: 'success',
      duration: 5
    }));

    setActiveTab(TABS.DETAILS);
  };

  const handleBackToSearch = () => {
    setIsSearching(true);
  };

  const isFormCompleted = () => {
    if (!selectedResponseType) return false;
    
    const requiredFields = RESPONSE_TYPES[selectedResponseType].requiredFields;
    return requiredFields.every(field => {
      const value = formValues[field];
      return value !== undefined && value !== null && value !== '';
    });
  };

  const handleTimeout = async () => {
    try {
      await onUnassign();
      dispatch(showFlashMessage({
        message: 'Dispute was automatically unassigned due to inactivity',
        type: 'warning',
        duration: 5
      }));
      history.push(Routes.UNRESOLVED);
    } catch (err) {
      dispatch(showFlashMessage({
        message: 'Failed to unassign dispute',
        type: 'error',
        duration: 5
      }));
    }
  };

  if (isLoading) {
    return <div className="disputes-loading-spinner">Loading dispute details...</div>;
  }

  if (error) {
    return (
      <div className="error-message">
        Error loading dispute: {error.message}
      </div>
    );
  }

  return (
    <TimeoutContainer
      onTimeout={handleTimeout}
      isEnabled={canEdit}
      warningMessage="This dispute will be automatically unassigned due to inactivity in:"
      timeoutDuration={30 * 60 * 1000} // 30 minutes
      warningDuration={1 * 60 * 1000} // 1 minute
    >
      <div className="dispute-detail">
        <div className="dispute-header">
          <div className="header-left">
            <motion.button
              className="header-button header-button--secondary"
              whileTap={{ scale: 0.95 }}
              onClick={handleBackToDisputes}
            >
              <i className="fas fa-chevron-left"></i>
              Back to Disputes
            </motion.button>
            {!(dispute?.status == DISPUTE_STATUS.ASSIGNED) && (
              <motion.button
                className="header-button header-button--primary"
                whileTap={{ scale: 0.95 }}
                onClick={() => handleAssignDispute()}
              >
                Get Next Dispute
              </motion.button>
            )}
          </div>
          <div className="header-right">
            <div className="dispute-meta">
              <div className="meta-item">
                <label>ID:</label>
                <span>{dispute.id}</span>
              </div>
              <div className="meta-item">
                <label>Status:</label>
                <span>{dispute.status}</span>
              </div>
              <div className="meta-item">
                <label>Due Date:</label>
                <span>{dueDate}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="dispute-content">
          <div className="dispute-left-panel">
            <div className="dispute-info-section">
              <div className="dispute-form-section">
                <Tabs value={activeTab} onChange={setActiveTab}>
                  <Tab label="Details" />
                  <Tab label="User Search" disabled={!canEdit} />
                  <Tab label="Resolution" disabled={!canEdit} />
                </Tabs>

                <div className="tab-content">
                  <TabPanel value={activeTab} index={TABS.DETAILS}>
                    <DisputeDetailsTab 
                      formValues={formValues}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      canEdit={canEdit}
                      resetFormValues={resetFormValues}
                      dispute={dispute}
                      hasChanges={hasChanges}
                      isUpdating={isUpdating}
                    />
                  </TabPanel>

                  <TabPanel value={activeTab} index={TABS.USER_SEARCH}>
                    <div className="dispute-section">
                      <UserSearchTab 
                        onUpdateDisputeInfo={handleUpdateDisputeInfo}
                        canEdit={canEdit}
                        searchState={searchState}
                        onSearchStateChange={setSearchState}
                        isSearching={isSearching}
                        onBackToSearch={handleBackToSearch}
                        onSearch={() => setIsSearching(false)}
                      />
                    </div>
                  </TabPanel>

                  <TabPanel value={activeTab} index={TABS.RESOLUTION}>
                    <DisputeResolutionTab 
                      canEdit={canEdit}
                      dispute={dispute}
                      formValues={formValues}
                      selectedResponseType={selectedResponseType}
                      setSelectedResponseType={setSelectedResponseType}
                      handleResolve={handleResolve}
                      hasChanges={hasChanges}
                      formCompleted={isFormCompleted()}
                      setActiveTab={setActiveTab}
                      TABS={TABS}
                    />
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>

          <div className="dispute-right-panel">
            <div className="document-viewer">
              <iframe
                src={dispute.dispute_url}
                title="Dispute Document"
                className="pdf-viewer"
              />
            </div>
          </div>
        </div>
      </div>
    </TimeoutContainer>
  );
};

export default DisputeDetail;
