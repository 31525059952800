import { configureStore } from "@reduxjs/toolkit";
import session from "../features/sessions/sessionSlice";
import errors from "../reducers/errors_reducer";
import entities from "../reducers/entities_reducer";
import ui from "../reducers/ui_reducer";
import flashMessageSlice from "../features/flashMessage/flashMessageSlice";
import { currentSessionState } from "../features/sessions/sessionSlice";
import logger from 'redux-logger'
import { internalApi } from '../api/internalApi'

const reducer = {
  session: session,
  errors: errors,
  entities: entities,
  ui: ui,
  flashMessage: flashMessageSlice,
  [internalApi.reducerPath]: internalApi.reducer
};

const preloadedState = {
  session: currentSessionState
};

export const store = configureStore({
  reducer, 
  // enable redux logger in browser console
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger, internalApi.middleware),
  // support devtools browser extension when not in production
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState
})
