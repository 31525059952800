import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import '../styles/css/timeoutModal.css';

const TimeoutWarning = ({ onExtend, message, remainingSeconds }) => {
  const [countdown, setCountdown] = useState(remainingSeconds);

  useEffect(() => {
    // creates the countdown by decrementing the countdown state by 1 every second
    const timer = setInterval(() => {
      setCountdown(prev => Math.max(0, prev - 1));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="timeout-warning">
      <div className="timeout-warning-content">
        <i className="fas fa-clock warning-icon"></i>
        <h3>Session Timeout Warning</h3>
        <p>{message}</p>
        <p><b>{countdown}</b></p>
        <motion.button
          className="extend-button"
          onClick={onExtend}
          whileTap={{ scale: 0.95 }}
        >
          Continue Working
        </motion.button>
      </div>
    </div>
  );
};

export default TimeoutWarning; 