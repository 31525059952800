import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  useUnassignDisputeMutation,
  useGetDisputeDetailsQuery,
  useAssignDisputeMutation 
} from "../disputesApi";
import { DISPUTE_STATUS } from "../helpers/disputeHelper";
import { showFlashMessage } from "../../flashMessage/flashMessageSlice";
import DisputeDetail from "../components/DisputeDetail";
import { currentEmployeeId } from "../../session/sessionSlice";
const DisputeDetailContainer = ({ match, history }) => {
  const { id } = match.params;
  const employeeId = useSelector(currentEmployeeId);
  const [currentAssignedDispute, setCurrentAssignedDispute] = useState({
    id: null,
    status: null
  });
  
  const [unassignDispute] = useUnassignDisputeMutation();
  const [assignDispute] = useAssignDisputeMutation();
  const { data: dispute } = useGetDisputeDetailsQuery(id);
  const dispatch = useDispatch();

  // Show warning if dispute is assigned to another employee
  useEffect(() => {
    if (dispute?.status === DISPUTE_STATUS.ASSIGNED && dispute.employee_id != employeeId) {
      dispatch(showFlashMessage({
        message: 'This dispute is already assigned to another employee and is not editable by you',
        type: 'warning',
        duration: 7
      }));
    }
  }, [dispute?.id, dispute?.status, dispute?.employee_id]);

  // Handle auto-assignment for unresolved disputes
  useEffect(() => {
    if (dispute?.status === DISPUTE_STATUS.UNRESOLVED) {
      handleAutoAssign();
    }
  }, [dispute?.id, dispute?.status]);

  const handleAutoAssign = async () => {
    try {
      const result = await assignDispute(id).unwrap();
      if (!result.success) {
        dispatch(showFlashMessage({
          message: 'Failed to assign dispute',
          type: 'error',
          duration: 5
        }));
      }
    } catch (err) {
      dispatch(showFlashMessage({
        message: err.data?.message || 'Failed to assign dispute',
        type: 'error',
        duration: 5
      }));
    }
  };

  const handleDisputeAssignment = (id, status) => {
    setCurrentAssignedDispute({ id, status });
  };

  const handleDisputeUnassignment = async () => {
    const { id, status } = currentAssignedDispute;
    if (id && status === DISPUTE_STATUS.ASSIGNED && dispute?.employee_id == employeeId) {
      try {
        await unassignDispute(id).unwrap();
        setCurrentAssignedDispute({ id: null, status: null });
        return true;
      } catch (err) {
        dispatch(showFlashMessage({
          message: err.data?.message || 'Failed to unassign dispute',
          type: 'error',
          duration: 5
        }));
        return false;
      }
    }
    return true;
  };

  return (
    <DisputeDetail
      match={match}
      history={history}
      currentAssignedDispute={currentAssignedDispute}
      onAssign={handleDisputeAssignment}
      onUnassign={handleDisputeUnassignment}
    />
  );
};

export default DisputeDetailContainer;
