import React, { useEffect, useCallback } from 'react';
import useTimeout from '../hooks/useTimeout';
import TimeoutWarning from './TimeoutWarning';

const TimeoutContainer = ({ 
  children,
  timeoutDuration = 15 * 60 * 1000,  // 15 minutes default
  warningDuration = 60 * 1000,       // 1 minute default
  onTimeout,
  warningMessage = "You will be timed out due to inactivity in:",
  isEnabled = true
}) => {
  const {
    showWarning,
    remainingSeconds,
    startTimer,
    resetTimer,
    cleanup
  } = useTimeout({
    timeoutDuration,
    warningDuration,
    onTimeout,
    isEnabled
  });

  // Handle user activity
  const handleUserActivity = useCallback(() => {
    if (isEnabled && !showWarning) {
      resetTimer();
    }
  }, [isEnabled, showWarning, resetTimer]);

  // Add/remove event listeners
  useEffect(() => {
    if (isEnabled) {
      document.addEventListener('mousemove', handleUserActivity);
      document.addEventListener('keydown', handleUserActivity);
      return () => {
        document.removeEventListener('mousemove', handleUserActivity);
        document.removeEventListener('keydown', handleUserActivity);
      };
    }
  }, [isEnabled, handleUserActivity]);

  // Start/cleanup timer
  useEffect(() => {
    if (isEnabled) {
      startTimer();
    }
    return cleanup;
  }, [isEnabled, startTimer, cleanup]);

  return (
    <>
      {showWarning && (
        <TimeoutWarning 
          onExtend={resetTimer}
          message={warningMessage}
          remainingSeconds={remainingSeconds}
        />
      )}
      {children}
    </>
  );
};

export default TimeoutContainer; 