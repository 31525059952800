import React, { useState } from 'react';
import { motion } from 'framer-motion';
import FormField from '../../../components/form/FormField';
import { showFlashMessage } from '../../../features/flashMessage/flashMessageSlice';
import { useDispatch } from 'react-redux';
import { DISPUTE_TYPES } from "../helpers/disputeHelper";
import { USER_INFO_FIELDS } from '../../../components/form/config/userFields';
import { ADDRESS_FIELDS } from '../../../components/form/config/addressConfig';

const DisputeDetailsTab = ({ 
  formValues, 
  handleChange, 
  canEdit, 
  resetFormValues,
  dispute,
  hasChanges,
  handleSubmit,
  isUpdating 
}) => {
  const dispatch = useDispatch();
  const [fieldValidity, setFieldValidity] = useState({});

  const handleFieldChange = (fieldName, value, isValid) => {
    handleChange(fieldName, value);
    setFieldValidity(prev => ({
      ...prev,
      [fieldName]: isValid
    }));
  };

  const isFormValid = Object.values(fieldValidity).every(isValid => isValid);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) {
      dispatch(showFlashMessage({
        message: 'Please correct invalid fields before saving',
        type: 'error',
        duration: 5
      }));
      return;
    }
    handleSubmit(e);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="search-results">
        <div className="form-sections">
          <section className="detail-section">
            <h4>User Information</h4>
            <div className="detail-grid">
              {USER_INFO_FIELDS.map(field => (
                <div key={field.name} className="detail-item">
                  <label>{field.label}</label>
                  <FormField
                    field={field}
                    value={formValues[field.name]}
                    onChange={handleFieldChange}
                    canEdit={canEdit}
                  />
                </div>
              ))}
            </div>
          </section>

          <section className="detail-section">
            <h4>Response Address</h4>
            <div className="detail-grid">
              {ADDRESS_FIELDS.map(field => (
                <div key={field.name} className="detail-item">
                  <label>{field.label}</label>
                  <FormField
                    field={field}
                    value={formValues[field.name]}
                    onChange={handleFieldChange}
                    canEdit={canEdit}
                  />
                </div>
              ))}
            </div>
          </section>

          <section className="detail-section">
            <h4>Dispute Type</h4>
            <div className="detail-grid">
              <div className="detail-item">
                <label>Type</label>
                <select
                  value={formValues.type}
                  onChange={(e) => handleChange('type', e.target.value)}
                  disabled={!canEdit}
                  className={!canEdit ? "disabled-input" : ""}
                >
                  <option value="">Select a type</option>
                  {Object.values(DISPUTE_TYPES).map((type) => (
                    <option key={type.key} value={type.display}>
                      {type.display}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </section>

          <section className="detail-section">
            <h4>Third Party Information</h4>
            <div className="detail-grid">
              <div className="detail-item">
                <label>Sent by a third party?</label>
                <select
                  value={formValues.sent_by_third_party || false}
                  onChange={(e) => handleChange('sent_by_third_party', e.target.value === 'true')}
                  disabled={!canEdit}
                  className={!canEdit ? "disabled-input" : ""}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>
              <div className="detail-item">
                <label>Contains a valid 3PA or POA?</label>
                <select
                  value={formValues.contains_valid_3pa || false}
                  onChange={(e) => handleChange('contains_valid_3pa', e.target.value === 'true')}
                  disabled={!canEdit}
                  className={!canEdit ? "disabled-input" : ""}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>
            </div>
          </section>
        </div>

        {canEdit && (
          <div className="form-actions">
            {hasChanges && (
              <motion.button
                type="button"
                className="clear-button"
                onClick={() => resetFormValues(dispute)}
                whileTap={{ scale: 0.95 }}
                disabled={isUpdating}
              >
                Clear Changes
              </motion.button>
            )}
            <motion.button
              type="submit"
              className="save-button"
              disabled={!hasChanges || isUpdating}
              whileTap={{ scale: 0.95 }}
            >
              {isUpdating ? "Saving..." : hasChanges ? "Save Changes" : "No Changes"}
            </motion.button>
          </div>
        )}
      </div>
    </form>
  );
};

export default DisputeDetailsTab; 