import React, { Component } from "react";
import { domain } from "../helpers/route_helper";
import { Link } from "react-router-dom";

class SurveyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      path: null,
      name: null,
      questions: []
    }
  }

  getSurvey = path => {
    return window.$
      .ajax({
        method: "GET",
        url: `${domain}/internal/api/survey_results`,
        data: { path }
      })
      .then(({users, name, questions}) => {
        this.setState({
          users,
          name,
          questions,
          path
        });
      });
  };

  componentDidMount() {
    let path = this.props.match.params.path;
    this.getSurvey(path);
  }


  render() {
    if (this.state.users.length === 0) {
      return <div />;
    }

    const display = this.state.questions.map((q,i) => (
      <div className="survey-question">
        <h2 className="survey-prompt">{q.question} - ({q.format})</h2>
        {
          Object.keys(q.choices).sort((a,b) => q.choices[b] - q.choices[a]).map(
            (c,i) => <p className="survey-response">{q.choices[c]} - {c}</p>
          )
        }
      </div>
    ))

    const userDisplay = this.state.users.map((s,i) => (
        <li><Link to={`/survey/${this.state.path}/${s}`}><h2 className="survey-prompt">{s}</h2></Link></li>
    ))

    return (
      <main>
        <div className="survey-show">
          <div>
            <h1 className="survey-title">{this.state.name}</h1>
            {display}
          </div>
          <ul className="user-display">
            {userDisplay}
          </ul>
        </div>
      </main>
    )
  }
}

export default SurveyList;
