import React, { Component } from "react";
import { domain } from "../helpers/route_helper";
import { withRouter } from "react-router";

class SurveyResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responses: [],
      questions: [],
      name: ""
    }
  }

  getSurveyResponse = (id, path) => {
    return window.$
      .ajax({
        method: "GET",
        url: `${domain}/internal/api/survey_response`,
        data: { id, path }
      })
      .then(res => {
        this.setState({
          id: res.id,
          name: res.name,
          questions: res.questions,
          responses: res.responses,
          firstName: res.first_name,
          lastName: res.last_name
        });
      });
  };

  componentDidMount() {
    let path = this.props.match.params.path;
    let id = this.props.match.params.id;
    this.getSurveyResponse(id, path);
  }


  render() {
    if (this.state.responses.length === 0) {
      return <div />;
    }

    const questions = this.state.questions.map(a=> a.question);
    const responses = this.state.responses;

    const display = questions.map((q,i) => (
      <div className="survey-question">
        <h2 className="survey-prompt">{q}</h2>
        <p className="survey-response">{responses[i] && responses[i] !== "" ? responses[i] : "No Answer"}</p>
      </div>
    ))

    return (
      <main>
        <div className="survey-list">
        <div>
          <h1 className="survey-title">{this.state.name} - {this.state.firstName} {this.state.lastName} ({this.state.id})</h1>
        </div>
        {display}
        </div>
      </main>
    )
  }
}

export default withRouter(SurveyResponse);
