import React, { useState } from "react";
import { motion } from "framer-motion";
import { useCreateDisputesMutation } from "../disputesApi";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showFlashMessage } from '../../flashMessage/flashMessageSlice';
import { Routes } from '../helpers/routeHelper';

const Landing = () => {
  const [files, setFiles] = useState([]);
  const [createDisputes, { isLoading }] = useCreateDisputesMutation();
  const dispatch = useDispatch();

  const handleFileUpload = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  const handleSubmitFiles = async () => {
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    // send the files to the server to create disputes
    await createDisputes({ data: formData })
      .unwrap()
      .then((response) => {
        // response.created_disputes is an array of file names that were successfully processed
        const successfulFileNames = response.created_disputes
        // remove the files that were successfully processed from the list
        setFiles(prevFiles => prevFiles.filter(file => !successfulFileNames.includes(file.name)));

        // count the number of files that were successfully processed and those that failed
        const successCount = successfulFileNames.length;
        const failCount = files.length - successCount;
        
        // create a message based on the number of files that were successfully processed and those that failed
        let message = `${successCount} dispute(s) created successfully.`;
        if (failCount > 0) {
          message += ` ${failCount} file(s) failed to process and remain in the list.`;
        }

        dispatch(showFlashMessage({
          message: message,
          duration: 5,
          type: failCount > 0 ? 'warning' : 'success'
        }));
      })
      .catch((rejected) => {
        dispatch(showFlashMessage({
          message: rejected.message || "An error occurred while creating disputes. Please try again.",
          duration: 5,
          type: 'error'
        }));
      });
  };

  return (
    <div className="landing-page">
      <div className="landing-container">
        <div className="action-cards">
          <div className="action-card upload-disputes">
            <h2>Upload New Disputes</h2>
            <p>Upload PDF files to create new disputes.</p>
            <motion.label
              htmlFor="file-upload"
              className="ui-button"
              whileTap={{ scale: 0.95 }}
            >
              Select Files
            </motion.label>
            <input
              type="file"
              accept=".pdf"
              multiple
              onChange={handleFileUpload}
              id="file-upload"
              style={{ display: "none" }}
              disabled={isLoading}
            />
          </div>

          <div className="action-card view-disputes">
            <h2>View Disputes</h2>
            <p>Manage and review existing disputes.</p>
            <Link to={isLoading ? "#" : Routes.UNRESOLVED} className="ui-button">
              View Unresolved Disputes
            </Link>
          </div>
        </div>

        {files.length > 0 && (
          <div className="file-upload-section">
            <h3>Selected Files</h3>
            <div className="file-list">
              {files.map((file, index) => (
                <div key={index} className="file-item">
                  <span>{file.name}</span>
                  <motion.button
                    className="remove-file"
                    onClick={() => handleRemoveFile(index)}
                    whileTap={{ scale: 0.95 }}
                    disabled={isLoading}
                  >
                    Remove
                  </motion.button>
                </div>
              ))}
            </div>
            <div className="upload-actions">
              <motion.label
                htmlFor="add-more-files"
                className="ui-button add-more-files"
                whileTap={{ scale: 0.95 }}
                disabled={isLoading}
              >
                Add More Files
              </motion.label>
              <input
                type="file"
                accept=".pdf"
                multiple
                onChange={handleFileUpload}
                id="add-more-files"
                style={{ display: "none" }}
                disabled={isLoading}
              />
              <motion.button
                className="ui-button remove-all"
                onClick={handleRemoveAllFiles}
                whileTap={{ scale: 0.95 }}
                disabled={isLoading}
              >
                Remove All Files
              </motion.button>
              <motion.button
                className="ui-button submit-files"
                onClick={handleSubmitFiles}
                whileTap={{ scale: 0.95 }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <div className="disputes-spinner"></div>
                    Uploading files...
                  </>
                ) : (
                  "Upload Files"
                )}
              </motion.button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Landing;
