import React from 'react';
import { motion } from 'framer-motion';
import { RESPONSE_TYPES } from "../helpers/disputeHelper";

const DisputeResolutionTab = ({
  canEdit,
  formValues,
  selectedResponseType,
  setSelectedResponseType,
  handleResolve,
  hasChanges,
  formCompleted,
  setActiveTab,
  TABS
}) => {
  return (
    <div className="search-results">
      <div className="form-sections">
        <section className="detail-section">
          <div className="section-header">
            <h4>Dispute Details</h4>
            <motion.button
              className="edit-button"
              onClick={() => setActiveTab(TABS.DETAILS)}
              whileTap={{ scale: 0.95 }}
            >
              <i className="fas fa-pencil-alt" />
              Edit Details
            </motion.button>
          </div>
          <div className="detail-grid">
            <div className="detail-item">
              <label>User ID</label>
              <span>{formValues.user_id}</span>
            </div>
            <div className="detail-item">
              <label>Loan ID</label>
              <span>{formValues.loan_id}</span>
            </div>
            <div className="detail-item">
              <label>Name</label>
              <span>{`${formValues.first_name || ''} ${formValues.last_name || ''}`}</span>
            </div>
            <div className="detail-item">
              <label>Response Address</label>
              <span>
                {formValues.street && (
                  <>
                    {formValues.street}<br />
                    {`${formValues.city || ''}, ${formValues.state || ''} ${formValues.zip || ''}`}
                  </>
                )}
              </span>
            </div>
            <div className="detail-item">
              <label>Dispute Type</label>
              <span>{formValues.type || 'Not specified'}</span>
            </div>
            <div className="detail-item">
              <label>Sent by Third Party</label>
              <span>{formValues.sent_by_third_party ? 'Yes' : 'No'}</span>
            </div>
            <div className="detail-item">
              <label>Valid 3PA/POA</label>
              <span>{formValues.contains_valid_3pa ? 'Yes' : 'No'}</span>
            </div>
          </div>
        </section>

        <section className="detail-section">
          <h4>Resolution</h4>
          <div className="detail-grid">
            <div className="detail-item">
              <label>Response Type</label>
              <select
                value={selectedResponseType}
                onChange={(e) => setSelectedResponseType(e.target.value)}
                disabled={!canEdit}
              >
                <option value="">Select a response type</option>
                {Object.values(RESPONSE_TYPES).map(({ key, display }) => (
                  <option key={key} value={key}>
                    {display}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </section>
      </div>

      <div className="form-actions">
        <motion.button
          className="save-button"
          onClick={handleResolve}
          disabled={!canEdit || !selectedResponseType || !formCompleted}
          whileTap={{ scale: 0.95 }}
        >
          {hasChanges ? "Save Changes and Resolve Dispute" : "Resolve Dispute"}
        </motion.button>
      </div>
    </div>
  );
};

export default DisputeResolutionTab; 