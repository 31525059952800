import React from 'react';
import { 
  validateZip, 
  formatZipInput, 
  validatePhone, 
  formatPhoneInput,
  validateSSN,
  formatSSNInput,
  validatePartialSSN,
  formatPartialSSNInput,
  validateDOB,
  formatDOBInput,
  US_STATES 
} from "../../helpers/input_helper";

const FormField = ({ 
  field, 
  value, 
  onChange, 
  canEdit = true
}) => {
  const fieldKey = field.key || field.name;

  const formatValue = (value) => {
    if (!value) return '';
    
    switch(fieldKey) {
      case 'zip':
        return formatZipInput(value);
      case 'phone_number':
        return formatPhoneInput(value);
      case 'full_ssn':
        return formatSSNInput(value);
      case 'partial_ssn':
        return formatPartialSSNInput(value);
      case 'dob':
        return formatDOBInput(value);
      default:
        return value;
    }
  };

  const formattedValue = React.useMemo(() => formatValue(value), [value, fieldKey]);

  const validate = (value) => {
    if (!value) return true;

    switch(fieldKey) {
      case 'zip':
        return validateZip(value);
      case 'phone_number':
        return validatePhone(value);
      case 'full_ssn':
        return validateSSN(value);
      case 'partial_ssn':
        return validatePartialSSN(value);
      case 'dob':
        return validateDOB(value);
      default:
        return true;
    }
  };

  const handleChange = (e) => {
    const newValue = formatValue(e.target.value);
    onChange(fieldKey, newValue, validate(newValue));
  };

  if (fieldKey === 'state') {
    return (
      <select
        id={fieldKey}
        name={fieldKey}
        value={value || ''}
        onChange={handleChange}
        disabled={!canEdit}
        className={canEdit && !validate(value) ? "invalid-input" : ""}
      >
        <option value="">Select a state</option>
        {US_STATES.map((state) => (
          <option key={state} value={state}>
            {state}
          </option>
        ))}
      </select>
    );
  }

  return (
    <input
      id={fieldKey}
      name={fieldKey}
      type={field.type || 'text'}
      value={formattedValue}
      onChange={handleChange}
      disabled={!canEdit}
      className={canEdit && !validate(formattedValue) ? "invalid-input" : ""}
      placeholder={field.placeholder}
    />
  );
};

export default FormField; 