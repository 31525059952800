import { internalApi } from '../../api/internalApi'

const BASE_EXT = 'disputes_dashboard'

export const disputesApi = internalApi.injectEndpoints({
  endpoints: (build) => ({
    createDisputes: build.mutation({
      query: ({ data }) => ({
        url: `${BASE_EXT}/create_disputes`,
        method: 'POST',
        body: data,
      })
    }),
    getUnresolvedDisputes: build.query({
      query: (page = 1) => ({
        url: `${BASE_EXT}/unresolved_disputes`,
        method: 'GET',
        params: { page }
      }),
      providesTags: ['Disputes']
    }),
    assignDispute: build.mutation({
      query: (id) => ({
        url: `${BASE_EXT}/assign_dispute`,
        method: 'PATCH',
        params: id ? { id } : undefined
      }),
      invalidatesTags: ['Disputes']
    }),
    getDisputeDetails: build.query({
      query: (id) => ({
        url: `${BASE_EXT}/dispute_details`,
        method: 'GET',
        params: { id }
      }),
      keepUnusedDataFor: 0,
      providesTags: ['Dispute']
    }),
    unassignDispute: build.mutation({
      query: (id) => ({
        url: `${BASE_EXT}/unassign_dispute`,
        method: 'PATCH',
        params: { id }
      }),
      invalidatesTags: ['Disputes']
    }),
    updateDispute: build.mutation({
      query: ({ id, dispute }) => ({
        url: `${BASE_EXT}/update_dispute`,
        method: 'PATCH',
        body: { id: id, dispute: dispute }
      }),
      invalidatesTags: ['Dispute']
    }),
    resolveDispute: build.mutation({
      query: ({ id, responseType }) => ({
        url: `${BASE_EXT}/resolve_dispute`,
        method: 'PATCH',
        body: { 
          id: id,
          response_type: responseType 
        }
      }),
      invalidatesTags: ['Dispute']
    }),
    searchUsers: build.query({
      query: (searchParams) => ({
        url: 'search/user_search',
        method: 'GET',
        params: searchParams
      })
    })
  })
})

export const { 
  useCreateDisputesMutation,
  useGetUnresolvedDisputesQuery,
  useAssignDisputeMutation,
  useGetDisputeDetailsQuery,
  useUnassignDisputeMutation,
  useUpdateDisputeMutation,
  useResolveDisputeMutation,
  useSearchUsersQuery
} = disputesApi
