import React from "react";
import { useSelector } from "react-redux";
import {
  searchedLoanIdNum,
  refiChain,
  accountInformation,
} from "../../../features/fcra/fcraSlice";
import PastAdjustments from "./PastAdjustments";
import { transformAccountInfoDate } from "../../../helpers/date_helper";
import { AccountHistory } from "./AccountHistory";
import { copyToClipboard } from "../../../util/clipboard";

export default function AccountInfo() {
  const searchedLoanId = useSelector(searchedLoanIdNum);
  const refiChainArray = useSelector(refiChain);
  const accountInfo = useSelector(accountInformation);

  const handleCopy = (value) => {
    copyToClipboard(value);
  };

  return (
    <section>
      <h2 id="accountInfoHeader">
        <b>Loan id {searchedLoanId} Account Information</b>
      </h2>
      <p>
        Refi Ids:{" "}
        {refiChainArray.length > 1 ? refiChainArray.join(", ") : "Not a refi"}
      </p>
      <PastAdjustments />
      <br />
      <h2>
        <b>Account status</b>
      </h2>
      <ul className="fcra-info" style={{ padding: 0, margin: 0 }}>
        <li data-label="Account status:">
          <span className="copyable" onClick={() => handleCopy(accountInfo.accountStatusCode)}>
            {accountInfo.accountStatusCode}
          </span>
        </li>
        <li data-label="Account type:">
          <span className="copyable" onClick={() => handleCopy(accountInfo.accountType)}>
            {accountInfo.accountType}
          </span>
        </li>
        <li data-label="Portfolio type:">
          <span className="copyable" onClick={() => handleCopy(accountInfo.portfolioType)}>
            {accountInfo.portfolioType}
          </span>
        </li>
        <li data-label="Special comment:">
          <span className="copyable" onClick={() => handleCopy(accountInfo.specialComment)}>
            {accountInfo.specialComment}
          </span>
        </li>
        <li data-label="Compliance condition:">
          <span className="copyable" onClick={() => handleCopy(accountInfo.complianceConditionCode)}>
            {accountInfo.complianceConditionCode}
          </span>
        </li>
        <li data-label="Terms duration:">
          <span className="copyable" onClick={() => handleCopy(accountInfo.termsDuration)}>
            {accountInfo.termsDuration}
          </span>
        </li>
        <li data-label="Payment rating:">
          <span className="copyable" onClick={() => handleCopy(accountInfo.paymentRatingCode)}>
            {accountInfo.paymentRatingCode}
          </span>
        </li>
        <li data-label="Interest type:">
          <span className="copyable" onClick={() => handleCopy(accountInfo.interestTypeIndicator)}>
            {accountInfo.interestTypeIndicator}
          </span>
        </li>
        <li data-label="Terms frequency:">
          <span className="copyable" onClick={() => handleCopy(accountInfo.termsFrequency)}>
            {accountInfo.termsFrequency}
          </span>
        </li>
      </ul>
      <br />
      <h2>
        <b>Account amounts</b>
      </h2>
      <ul className="fcra-info" style={{ padding: 0, margin: 0 }}>
        <li data-label="Current balance:">
          <span className="copyable" onClick={() => handleCopy(accountInfo.currentBalance)}>
            ${accountInfo.currentBalance}
          </span>
        </li>
        <li data-label="Amount past due:">
          <span className="copyable" onClick={() => handleCopy(accountInfo.amountPastDue)}>
            ${accountInfo.amountPastDue}
          </span>
        </li>
        <li data-label="Highest credit:">
          <span className="copyable" onClick={() => handleCopy(accountInfo.highCreditAmount)}>
            ${accountInfo.highCreditAmount}
          </span>
        </li>
        <li data-label="Original charge-off amount:">
          <span className="copyable" onClick={() => handleCopy(accountInfo.originalChargeOffAmount)}>
            ${accountInfo.originalChargeOffAmount}
          </span>
        </li>
        <li data-label="Scheduled payment amount:">
          <span className="copyable" onClick={() => handleCopy(accountInfo.scheduledPaymentAmount)}>
            ${accountInfo.scheduledPaymentAmount}
          </span>
        </li>
        <li data-label="Actual payment amount:">
          <span className="copyable" onClick={() => handleCopy(accountInfo.actualPaymentAmount)}>
            ${accountInfo.actualPaymentAmount}
          </span>
        </li>
      </ul>
      <br />
      <h2>
        <b>Account dates</b>
      </h2>
      <ul className="fcra-info" style={{ padding: 0, margin: 0 }}>
        <li data-label="Date opened:">
          <span className="copyable" onClick={() => handleCopy(transformAccountInfoDate(accountInfo.dateOpened))}>
            {transformAccountInfoDate(accountInfo.dateOpened)}
          </span>
        </li>
        <li data-label="Date of account info:">
          <span className="copyable" onClick={() => handleCopy(transformAccountInfoDate(accountInfo.dateOfAccountInfo))}>
            {transformAccountInfoDate(accountInfo.dateOfAccountInfo)}
          </span>
        </li>
        <li data-label="Date of last payment:">
          <span className="copyable" onClick={() => handleCopy(transformAccountInfoDate(accountInfo.dateOfLastPayment))}>
            {transformAccountInfoDate(accountInfo.dateOfLastPayment)}
          </span>
        </li>
        <li data-label="Date closed:">
          <span className="copyable" onClick={() => handleCopy(transformAccountInfoDate(accountInfo.dateClosed))}>
            {transformAccountInfoDate(accountInfo.dateClosed)}
          </span>
        </li>
        <li data-label="Date of first delinquency:">
          <span className="copyable" onClick={() => handleCopy(transformAccountInfoDate(accountInfo.fcraFirstDateOfDelinquency))}>
            {transformAccountInfoDate(accountInfo.fcraFirstDateOfDelinquency)}
          </span>
        </li>
      </ul>
      <br />
      <AccountHistory accountHistory={accountInfo.sevenYearPaymentHistory} />
    </section>
  );
}