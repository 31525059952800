import React, { useEffect, useState } from "react";
import DocumentViewer from "./DocumentViewerAndSelector";
import VerifyIncomeForm from "./VerifyIncomeForm";
import Header from "./Header"; 
import {useDispatch, useSelector} from "react-redux";
import {
  fetchNextLoan, fetchReRequestReasons, selectErrors, selectIncomeForLoanVerified,
  selectLoanId, selectUserName,
  selectMinPaystubDate, selectNewPaystubRequested,
  selectStatus, selectPaystubs, selectNeedReviewTotal, selectReRequestReasons
} from "../../features/incomeVerification/incomeVerificationSlice";
import RequestNewPaystubForm from "./RequestNewPaystubForm";


const IncomeVerification = () => {

  const dispatch = useDispatch();
  // These are "slice" selectors that allow access to specific parts of the Redux state tied to Income Verification
  const minPaystubDate = useSelector(selectMinPaystubDate);
  const loanIdToReview = useSelector(selectLoanId);
  const userName = useSelector(selectUserName);
  const serverErrorMessages = useSelector(selectErrors);
  const incomeForLoanVerified = useSelector(selectIncomeForLoanVerified);
  const fetchNextLoanStatus = useSelector(selectStatus);
  const newPaystubRequested = useSelector(selectNewPaystubRequested);
  const paystubs = useSelector(selectPaystubs);
  const needReviewTotal = useSelector(selectNeedReviewTotal);
  const reRequestReasons = useSelector(selectReRequestReasons);

  const [showVerifyIncomeForm, setShowVerifyIncomeForm] = useState(true);

  const requestLoanForIncomeVerification = () => {
    //MD: We always want to have `showVerifyIncomeForm` as true when a new loan is requested. So set it to true here
    setShowVerifyIncomeForm(true);
    dispatch(fetchNextLoan())
    if (!reRequestReasons) {
      dispatch(fetchReRequestReasons())
    }
  };

  useEffect(() => {
    // MD: Could add a dependency here on incomeForLoanVerified and automatically fetch the next loan
    requestLoanForIncomeVerification();
  }, [dispatch]);

  return (
    <div>
      <Header needReviewTotal={needReviewTotal}/>
      <div className="blog-post">
        <div className="image-feed income-image-scroll" style={{ overflow: "auto" }}>
          { loanIdToReview && paystubs.map(paystub =>
            (
              <DocumentViewer imageUrl={paystub.url} imageFileType={paystub.stub_file_type} />
            ))
          }
        </div>
        <div className="blog-edit">
          { loanIdToReview ?
            (
              incomeForLoanVerified ?
                (
                  <div>
                    <p>Income verified for Loan ID {loanIdToReview}!</p>
                    <button className="ui-button" type="button" onClick={requestLoanForIncomeVerification}>Get next item</button>
                  </div>
                ) :
                (
                  showVerifyIncomeForm ?
                    (
                      <div>
                        <h1 className="centered-heading">Loan ID: {loanIdToReview}</h1>
                        <VerifyIncomeForm minPaystubDate={minPaystubDate} loanId={loanIdToReview} serverErrorMessages={serverErrorMessages} userName={userName}/>
                        <button className="ui-button"  type="button" onClick={() => setShowVerifyIncomeForm(false)}>Switch to re-request form</button>
                      </div>
                    ) :
                    (
                      newPaystubRequested ?
                        (
                        <div>
                          <p>New paystub requested for Loan ID {loanIdToReview}!</p>
                          <button className="ui-button" type="button" onClick={requestLoanForIncomeVerification}>Get next item</button>
                        </div>
                        ) : (
                        <div>
                          <RequestNewPaystubForm loanId={loanIdToReview} serverErrorMessages={serverErrorMessages}/>
                          <button className="ui-button" type="button" onClick={() => setShowVerifyIncomeForm(true)}>Switch to verification form</button>
                        </div>
                        )

                    )
                )
            )
            :
            (<div><p>{fetchNextLoanStatus}</p></div>)}
        </div>
      </div>
    </div>
  );
};

export default IncomeVerification;
