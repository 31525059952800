import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Landing from "./components/Landing";
import Unresolved from "./components/Unresolved";
import DisputeDetailContainer from "./containers/DisputeDetailContainer";
import { Routes } from "./helpers/routeHelper";
import "../../styles/css/disputesDashboard.css";

const DisputesDashboard = () => {
  return (
    <div className="disputes-dashboard">
      <div className="dashboard-content">
        <h1>Disputes Dashboard</h1>
        <div className="dashboard-body">
          <Switch>
            <Route exact path={Routes.LANDING} component={Landing} />
            <Route exact path={Routes.UNRESOLVED} component={Unresolved} />
            <Route exact path={Routes.DETAIL} component={DisputeDetailContainer} />
            <Redirect to={Routes.LANDING} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default DisputesDashboard;
