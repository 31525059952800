import React, { Component } from "react";
import { connect } from "react-redux";
import { signOut } from "../features/sessions/sessionSlice";
import { Switch } from "react-router-dom";
import { withRouter } from "react-router";
import { AuthRoute, ProtectedRoute } from "../util/route_util";
import Login from "./Login";
import EnableMfa from "./EnableMfa";
import Dashboard from "./Dashboard";
import UserShow from "./UserShow_container";
import Visualize from "./Visualize";
import IdentificationList from "./IdentificationList";
import IncomeVerification from "./IncomeVerification/IncomeVerification";
import SurveyResponse from "./SurveyResponse";
import SurveyList from "./SurveyList";
import SurveyShow from "./SurveyShow";
import BlogPost from "./blog/BlogPost";
import MacroEditor from "./MacroEditor";
import EmbeddedSandbox from "./GraphQL/GraphQLSandbox"
import FCRA from "./Fcra/FCRA";
import Acdv from "./Acdv";
import DisputesDashboard from "../features/disputesDashboard/DisputesDashboard";
import "../styles/css/App.css";
import "../styles/css/internal.css";
// Importing CSS for SVG loaders
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-dates/initialize";
import CreditReportAdjustments from "./CreditReportingAdjustments/CreditReportAdjustments";
import FlashMessage from "./FlashMessage";
import Navbar from "./Navbar_container";

class App extends Component {
  constructor() {
    super();
    window.$.ajaxSetup({
      statusCode: {
        401: () => {
          if (this.props.currentUser) {
            alert("Employee Credentials have expired. Please log in again.");
          }
          this.props.signOut();
        },
      },
    });
  }
  render() {
    return (
      <main className="App">
        <Navbar />
        <FlashMessage />
        <Switch>
          <AuthRoute component={Login} exact path="/login" />
          <AuthRoute component={EnableMfa} exact path="/enable-mfa" />
          <ProtectedRoute component={UserShow} exact path="/user" />
          <ProtectedRoute
            component={Visualize}
            exact
            path="/visualize/:chart"
          />
          <ProtectedRoute
            component={IdentificationList}
            path="/identifications"
          />
          <ProtectedRoute
            component={IncomeVerification}
            exact
            path="/income"
          />
          <ProtectedRoute
            component={CreditReportAdjustments}
            exact
            path="/cr_adj"
          />
          <ProtectedRoute
            component={DisputesDashboard}
            exact
            path="/disputes*"
          />
          <ProtectedRoute
            component={SurveyResponse}
            exact
            path="/survey/:path/:id"
          />
          <ProtectedRoute component={SurveyShow} exact path="/surveys/:path" />
          <ProtectedRoute component={SurveyList} exact path="/surveys" />
          <ProtectedRoute component={BlogPost} exact path="/blog_post" />
          <ProtectedRoute component={MacroEditor} exact path="/macros" />
          <ProtectedRoute component={FCRA} exact path="/fcra" />
          <ProtectedRoute component={Acdv} exact path="/acdv" />
          <ProtectedRoute component={EmbeddedSandbox} exact path="/graph_ql" />
          <ProtectedRoute component={Dashboard} path="/" />
        </Switch>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));