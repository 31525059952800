import React from "react";
import { useSelector } from "react-redux";
import { pastAdjustments } from "../../../features/fcra/fcraSlice";
import { formatYearFirstDashDate } from "../../../helpers/date_helper";

export default function PastAdjustments() {
  const adjustments = useSelector(pastAdjustments);

  const adjustmentsDisplay = () => {
    if (adjustments.length === 0) {
      return (
        <p style={{ padding: 0, margin: 0, marginLeft: "10px" }}>
          <em>No past credit report adjustments.</em>
        </p>
      );
    }

    return (
      <ul className="fcra-info" style={{ padding: 0, margin: 0 }}>
        {adjustments.map((adjustment, index) => (
          <li key={index} className="payment-detail">
            <span data-label="Processed:">
              {formatYearFirstDashDate(adjustment.processed)}
            </span>
            <ul
              className="fcra-info"
              style={{ padding: 0, margin: 0, marginLeft: "20px" }}
            >
              <li className="payment-detail" data-label="Type:">
                {adjustment.adj_type}
              </li>
              <li className="payment-detail" data-label="Reason:">
                {adjustment.reason}
              </li>
            </ul>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div>
      <h2>Past Credit Report Adjustments:</h2>
      {adjustmentsDisplay()}
    </div>
  );
}
