import React from "react";
import Search from "./Search_container";
import { signOut } from "../features/sessions/sessionSlice";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import "../styles/css/navbar.css";
import useSignedIn from "../hooks/useSignedIn";

const Navbar = ({ history }) => {
  const dispatch = useDispatch();

  const pusher = (path) => {
    if (history.location.pathname !== path) history.push(path);
  };

  const signedIn = useSignedIn();

  if (!signedIn) {
    return null;
  }

  return (
    <div className="navbar">
      <Search />
      <ul>
        <li key={1} onClick={() => pusher("/")}>
          Home
        </li>

        <li key={2} className="dropdown">
          <span className="dropdown-btn">Dashboards</span>
          <div className="dropdown-content">
            <div className="dropdown-item" onClick={() => pusher("/disputes")}>Disputes</div>
            <div className="dropdown-item" onClick={() => pusher("/fcra")}>FCRA</div>
            <div className="dropdown-item" onClick={() => pusher("/identifications")}>Identifications</div>
            <div className="dropdown-item" onClick={() => pusher("/income")}>Income Review</div>
          </div>
        </li>

        <li key={3} className="dropdown">
          <span className="dropdown-btn">Editors</span>
          <div className="dropdown-content">
            <div className="dropdown-item" onClick={() => pusher("/blog_post")}>Blog</div>
            <div className="dropdown-item" onClick={() => pusher("/macros")}>Macros</div>
          </div>
        </li>

        <li key={4} className="dropdown">
          <span className="dropdown-btn">Search</span>
          <div className="dropdown-content">
            <div className="dropdown-item" onClick={() => pusher("/graph_ql")}>GraphQL</div>
            <div className="dropdown-item" onClick={() => pusher("/user")}>User Details</div>
          </div>
        </li>

        <li key={5} className="dropdown">
          <span className="dropdown-btn">Analytics</span>
          <div className="dropdown-content">
            <div className="dropdown-item" onClick={() => pusher("/visualize/cs")}>CS</div>
          </div>
        </li>

        <li key={6} onClick={() => dispatch(signOut())}>Log Out</li>
      </ul>
    </div>
  );
};

export default withRouter(Navbar);
