import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideFlashMessage } from '../features/flashMessage/flashMessageSlice';
import '../styles/css/FlashMessage.css';

const FlashMessage = () => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.flashMessage.messages);

  useEffect(() => {
    messages.forEach(msg => {
      const timer = setTimeout(() => {
        dispatch(hideFlashMessage(msg.id));
      }, msg.duration * 1000);

      return () => clearTimeout(timer);
    });
  }, [messages, dispatch]);

  if (messages.length === 0) return null;

  return (
    <div className="flash-message-container">
      {messages.map((msg, index) => (
        <div 
          key={msg.id}
          className={`flash-message ${msg.type}`}
          style={{ 
            top: `${20 + (index * 70)}px`  // Stack messages with spacing
          }}
        >
          <span>{msg.message}</span>
          <button onClick={() => dispatch(hideFlashMessage(msg.id))}>X</button>
        </div>
      ))}
    </div>
  );
};

export default FlashMessage;
