import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchIdentifications,
  completeIdentification
} from "../actions/task_actions";
import UserIdentification from "./UserIdentification";
import "../styles/css/review.css";

class IdentificationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isManualHoldOpen: false,
      isRegularCasesOpen: true  // Default to open
    };
  }

  componentDidMount() {
    this.props.fetchIdentifications();
  }

  toggleManualHold = () => {
    this.setState(prevState => ({
      isManualHoldOpen: !prevState.isManualHoldOpen
    }));
  }

  toggleRegularCases = () => {
    this.setState(prevState => ({
      isRegularCasesOpen: !prevState.isRegularCasesOpen
    }));
  }

  render() {
    const regularIdentifications = this.props.identifications.filter(
      id => id.verified !== -7
    );
    const manualHoldIdentifications = this.props.identifications.filter(
      id => id.verified === -7
    );

    return (
      <div>
        
        <h1 className="identifications-dashboard-header">
          Identifications Dashboard
        </h1>
        
        <div className="manual-fraud-section">
          <div className="manual-fraud-hold-container">
            <button 
              className="manual-fraud-hold-button"
              onClick={this.toggleRegularCases}
            >
              Regular Cases ({regularIdentifications.length})
              <i className={`fas fa-chevron-${this.state.isRegularCasesOpen ? 'up' : 'down'}`}></i>
            </button>
            
            {this.state.isRegularCasesOpen && (
              <div className="manual-fraud-hold-dropdown review_container">
                {regularIdentifications.map(user_id_card => (
                  <UserIdentification
                    key={user_id_card.user_id}
                    user_id_card={user_id_card}
                    completeIdentification={this.props.completeIdentification}
                  />
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="manual-fraud-section">
          <div className="manual-fraud-hold-container">
            <button 
              className="manual-fraud-hold-button"
              onClick={this.toggleManualHold}
            >
              Manual Hold Cases ({manualHoldIdentifications.length})
              <i className={`fas fa-chevron-${this.state.isManualHoldOpen ? 'up' : 'down'}`}></i>
            </button>
            
            {this.state.isManualHoldOpen && (
              <div className="manual-fraud-hold-dropdown review_container">
                {manualHoldIdentifications.map(user_id_card => (
                  <UserIdentification
                    key={user_id_card.user_id}
                    user_id_card={user_id_card}
                    completeIdentification={this.props.completeIdentification}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  identifications: Object.values(
    state.entities.identifications.identifications
  ),
  aws_token: state.entities.identifications.accessKeyId,
  bucket: state.entities.identifications.bucket,
  secretAccessKey: state.entities.identifications.secretAccessKey
});

const mapDispatchToProps = dispatch => ({
  fetchIdentifications: () => dispatch(fetchIdentifications()),
  completeIdentification: (id, decision) =>
    dispatch(completeIdentification(id, decision))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdentificationList);
