import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_DURATION = 7;

const flashMessageSlice = createSlice({
  name: 'flashMessage',
  initialState: {
    messages: []
  },
  reducers: {
    showFlashMessage: (state, action) => {
      const messageId = Date.now();
      state.messages.push({
        id: messageId,
        message: action.payload.message,
        duration: action.payload.duration || DEFAULT_DURATION,
        type: action.payload.type || 'success'
      });
    },
    hideFlashMessage: (state, action) => {
      state.messages = state.messages.filter(msg => msg.id !== action.payload);
    },
  },
});

export const { showFlashMessage, hideFlashMessage } = flashMessageSlice.actions;
export default flashMessageSlice.reducer;
