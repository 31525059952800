import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchUsersQuery } from '../disputesApi';
import { showFlashMessage } from '../../flashMessage/flashMessageSlice';
import { motion } from 'framer-motion';
import { SEARCH_FIELDS } from '../../../components/form/config/searchConfig';
import FormField from '../../../components/form/FormField';
import { 
  stripPhoneFormat, 
  stripSSNFormat 
} from '../../../helpers/input_helper';

const MINIMUM_SECONDARY_FIELDS = 2;

const UserSearchTab = ({ 
  onUpdateDisputeInfo, 
  canEdit,
  searchState,
  onSearchStateChange,
  isSearching,
  onBackToSearch,
  onSearch
}) => {
  const [expandedUserId, setExpandedUserId] = useState(null);
  const [fieldValidity, setFieldValidity] = useState({});
  
  const dispatch = useDispatch();

  const validateSearchCriteria = () => {
    const params = searchState.params || {};

    // Check for unique identifiers
    const hasUniqueIdentifier = SEARCH_FIELDS.unique
      .some(field => params[field.key]?.trim());

    if (hasUniqueIdentifier) return true;

    // Count filled secondary fields
    const secondaryFieldCount = SEARCH_FIELDS.secondary
      .filter(field => params[field.key]?.trim())
      .length;

    return secondaryFieldCount >= MINIMUM_SECONDARY_FIELDS;
  };

  const { data: searchResults, isFetching } = useSearchUsersQuery(
    searchState.params,
    { 
      skip: !searchState.shouldSearch || !validateSearchCriteria(),
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  const handleInputChange = (key, value, isValid) => {
    setFieldValidity(prev => ({
      ...prev,
      [key]: isValid
    }));

    onSearchStateChange(prev => ({
      ...prev,
      shouldSearch: false,
      params: {
        ...prev.params,
        [key]: value
      }
    }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    
    if (!validateSearchCriteria()) {
      dispatch(showFlashMessage({
        message: 'Please provide either a primary identifier or at least 2 secondary search fields',
        type: 'error',
        duration: 5
      }));
      return;
    }

    // Check if any fields are invalid
    const hasInvalidFields = Object.entries(searchState.params)
      .some(([key, value]) => value && fieldValidity[key] === false);

    if (hasInvalidFields) {
      dispatch(showFlashMessage({
        message: 'Please correct invalid fields before searching',
        type: 'error',
        duration: 5
      }));
      return;
    }

    // Strip formatting before searching
    const cleanParams = {
      ...searchState.params,
      phone_number: stripPhoneFormat(searchState.params.phone_number),
      full_ssn: stripSSNFormat(searchState.params.full_ssn)
    };

    onSearchStateChange(prev => ({
      ...prev,
      shouldSearch: true,
      params: cleanParams
    }));
    onSearch();
  };

  const handlePageChange = (newPage) => {
    onSearchStateChange(prev => ({
      ...prev,
      params: {
        ...prev.params,
        page: newPage
      }
    }));
  };

  const handleExpandUser = (userId) => {
    setExpandedUserId(userId === expandedUserId ? null : userId);
  };

  // Find the expanded user from search results
  const expandedUser = searchResults?.users?.find(user => user.id === expandedUserId);

  const handleValidationError = ({ field, message }) => {
    dispatch(showFlashMessage({
      message,
      type: 'error',
      duration: 5
    }));
  };

  return (
    <div className="user-search-tab">
      {isSearching ? (
        <form onSubmit={handleSearch} className="search-form">
          <div className="form-sections">
            <section className="detail-section">
              <h4>Unique Identifiers</h4>
              <div className="detail-grid">
                {SEARCH_FIELDS.unique.map(field => (
                  <div key={field.key} className="detail-item">
                    <label htmlFor={field.key}>{field.label}</label>
                    <FormField
                      field={field}
                      value={searchState.params[field.key]}
                      onChange={handleInputChange}
                      canEdit={canEdit}
                      onValidationError={handleValidationError}
                    />
                  </div>
                ))}
              </div>
            </section>

            <section className="detail-section">
              <h4>Search Fields</h4>
              <div className="detail-grid">
                {SEARCH_FIELDS.secondary.map(field => (
                  <div key={field.key} className="detail-item">
                    <label htmlFor={field.key}>{field.label}</label>
                    <FormField
                      field={field}
                      value={searchState.params[field.key]}
                      onChange={handleInputChange}
                      canEdit={canEdit}
                      onValidationError={handleValidationError}
                    />
                  </div>
                ))}
              </div>
            </section>
          </div>
          <div className="form-actions">
            <motion.button 
              type="submit" 
              className="primary-button"
              disabled={!canEdit || isFetching}
              whileTap={{ scale: 0.95 }}
            >
              {isFetching ? (
                <>
                  <div className="disputes-spinner"></div>
                  Searching...
                </>
              ) : (
                "Search"
              )}
            </motion.button>
          </div>
        </form>
      ) : (
        <div className="search-results-section">
          {!expandedUserId ? (
            <div className="search-results-section">
              <div className="search-results-controls">
                <motion.button
                  className="back-to-results-button"
                  onClick={onBackToSearch}
                  whileTap={{ scale: 0.95 }}
                >
                  <i className="fas fa-chevron-left" />
                  Back to Search
                </motion.button>
                {!isFetching && searchResults?.meta?.search_type && (
                  <div className={`confidence-pill ${searchResults.meta.search_type}`}>
                    Search results: {searchResults.meta.search_type}
                  </div>
                )}
              </div>

              <div className="search-results">
                {isFetching ? (
                  <div className="search-results-loading">
                    <div className="disputes-spinner"></div>
                    <p>Searching for users...</p>
                  </div>
                ) : searchResults?.users?.length > 0 ? (
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th>Name / Contact</th>
                          <th>Address</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchResults.users.map(user => (
                          <tr key={user.id}>
                            <td>
                              <div className="user-info">
                                <div className="user-name">
                                  {`${user.first_name} ${user.last_name}`}
                                </div>
                                <div className="user-contact">
                                  {user.email && <span title={user.email}>{user.email}</span>}
                                  {user.phone && <span title={user.phone_number}>{user.phone_number}</span>}
                                </div>
                              </div>
                            </td>
                            <td>
                              {user.address && (
                                <div className="user-address" title={`${user.address.street}, ${user.address.city}, ${user.address.state} ${user.address.zip}`}>
                                  {`${user.address.street}, ${user.address.city}, ${user.address.state} ${user.address.zip}`}
                                </div>
                              )}
                            </td>
                            <td>
                              <div className="user-actions">
                                <motion.button 
                                  onClick={() => handleExpandUser(user.id)}
                                  className="expand-details-button"
                                  title="View Details"
                                  whileTap={{ scale: 0.95 }}
                                >
                                  <i className="fas fa-info-circle" />
                                </motion.button>
                                <motion.button 
                                  onClick={() => onUpdateDisputeInfo(user)}
                                  className="select-user-button"
                                  disabled={!canEdit}
                                  title="Select User"
                                  whileTap={{ scale: 0.95 }}
                                >
                                  <i className="fas fa-check" />
                                </motion.button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {searchResults.meta?.total_pages > 1 && (
                      <div className="pagination">
                        <motion.button
                          className="pagination-button"
                          onClick={() => handlePageChange(searchResults.meta.current_page - 1)}
                          disabled={searchResults.meta.current_page === 1 || isFetching}
                          whileTap={{ scale: 0.95 }}
                        >
                          Previous
                        </motion.button>
                        <span>
                          Page {searchResults.meta.current_page} of {searchResults.meta.total_pages}
                        </span>
                        <motion.button
                          className="pagination-button"
                          onClick={() => handlePageChange(searchResults.meta.current_page + 1)}
                          disabled={searchResults.meta.current_page === searchResults.meta.total_pages || isFetching}
                          whileTap={{ scale: 0.95 }}
                        >
                          Next
                        </motion.button>
                      </div>
                    )}
                  </>
                ) : searchResults && (
                  <div className="search-results-empty">
                    <i className="fas fa-search" />
                    <p>No users found matching your search criteria</p>
                    <p className="hint">Try adjusting your search terms or using fewer filters</p>
                  </div>
                )}
              </div>
            </div>
          ) : expandedUser ? (
            <div className="search-results-section">
              <div className="search-results-controls">
                <motion.button
                  className="back-to-results-button"
                  onClick={() => setExpandedUserId(null)}
                  whileTap={{ scale: 0.95 }}
                >
                  <i className="fas fa-chevron-left" />
                  Back to Results
                </motion.button>
                <motion.button 
                  onClick={() => onUpdateDisputeInfo(expandedUser)}
                  className="select-user-button-large"
                  disabled={!canEdit}
                  whileTap={{ scale: 0.95 }}
                >
                  <i className="fas fa-check" /> Select This User
                </motion.button>
              </div>

              <div className={`search-results`}>
                <div className="form-sections">
                  <section className="detail-section">
                    <h4>User Information</h4>
                    <div className="detail-grid">
                      <div className="detail-item">
                        <label>User ID</label>
                        <span>{expandedUser.id}</span>
                      </div>
                      <div className="detail-item">
                        <label>Name</label>
                        <span>{`${expandedUser.first_name} ${expandedUser.last_name}`}</span>
                      </div>
                      <div className="detail-item">
                        <label>DOB</label>
                        <span>{expandedUser.birthday}</span>
                      </div>
                      <div className="detail-item">
                        <label>SSN (Last 4)</label>
                        <span>{expandedUser.ssn_last_four}</span>
                      </div>
                    </div>
                  </section>

                  <section className="detail-section">
                    <h4>Contact Information</h4>
                    <div className="detail-grid">
                      <div className="detail-item">
                        <label>Email</label>
                        <span>{expandedUser.email}</span>
                      </div>
                      <div className="detail-item">
                        <label>Phone</label>
                        <span>{expandedUser.phone_number}</span>
                      </div>
                    </div>
                  </section>

                  <section className="detail-section">
                    <h4>Address</h4>
                    <div className="detail-grid">
                      {expandedUser.address && (
                        <>
                          <div className="detail-item">
                            <label>Street</label>
                            <span>{expandedUser.address.street}</span>
                          </div>
                          <div className="detail-item">
                            <label>City</label>
                            <span>{expandedUser.address.city}</span>
                          </div>
                          <div className="detail-item">
                            <label>State</label>
                            <span>{expandedUser.address.state}</span>
                          </div>
                          <div className="detail-item">
                            <label>ZIP</label>
                            <span>{expandedUser.address.zip}</span>
                          </div>
                        </>
                      )}
                    </div>
                  </section>

                  <section className="detail-section">
                    <h4>Loan Information</h4>
                    <div className="loans-list">
                      <div className="loan-section">
                        <h5>Active Loan Id</h5>
                        {expandedUser.active_loan_id ? (
                          <div className="loan-item">
                            {expandedUser.active_loan_id}
                          </div>
                        ) : (
                          <div className="loan-item empty">No active loan</div>
                        )}
                      </div>
                      <br></br>
                      <div></div>
                      <div className="loan-section">
                        <h5>Other Funded Loan Ids</h5>
                        {expandedUser.other_funded_loan_ids?.length > 0 ? (
                          expandedUser.other_funded_loan_ids.map(loanId => (
                            <div key={loanId} className="loan-item">
                              {loanId}
                            </div>
                          ))
                        ) : (
                          <div className="loan-item empty">No other funded loans</div>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          ) : (
            <div className="error-message">User details not found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserSearchTab; 