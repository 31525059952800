import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { useGetUnresolvedDisputesQuery, useAssignDisputeMutation } from '../disputesApi';
import { formatYearFirstDashDate } from '../../../helpers/date_helper';
import { Routes } from '../helpers/routeHelper';
import { useDispatch } from 'react-redux';
import { showFlashMessage } from '../../flashMessage/flashMessageSlice';

const Unresolved = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const tableBodyRef = useRef(null);
  const { data, error, isLoading, isFetching } = useGetUnresolvedDisputesQuery(currentPage, {
    pollingInterval: 30000,
    refetchOnFocus: true
  });
  const [assignDispute] = useAssignDisputeMutation();
  const dispatch = useDispatch();
  const handlePageChange = (newPage) => {
    if (newPage < 1 || (data?.meta?.total_pages && newPage > data.meta.total_pages)) {
      return;
    }
    setCurrentPage(newPage);
    if (tableBodyRef.current) {
      tableBodyRef.current.scrollTop = 0;
    }
  };

  const formatName = (firstName, lastName) => {
    if (!firstName && !lastName) return 'N/A';
    if (!firstName) return lastName;
    if (!lastName) return firstName;
    return `${firstName.charAt(0)}. ${lastName}`;
  };

  const handleAssignDispute = async (disputeId = null) => {
    try {
      const result = await assignDispute(disputeId).unwrap();
      if (result.success) {
        history.push(Routes.getDetailPath(result.dispute_id));
      }
    } catch (err) {
      dispatch(showFlashMessage({
        message: err.data?.message || 'Failed to assign dispute',
        type: 'error',
        duration: 5
      }));
    }
  };

  if (isLoading) {
    return <div className="disputes-loading-spinner">Loading...</div>;
  }

  if (error) {
    return <div className="error-message">Error loading disputes: {error.message}</div>;
  }

  const { disputes, meta } = data || { disputes: [], meta: {} };
  const { current_page, total_pages, total_disputes } = meta;

  // no disputes present? keep it simple
  if (total_disputes === 0) {
    return (
      <div className="unresolved-disputes">
        <div className="disputes-header">
          <h2>Unresolved Disputes</h2>
        </div>
        <div className="no-disputes-message">
          No unresolved disputes found.
        </div>
      </div>
    );
  }

  // render the pagination buttons
  const renderPagination = () => {
    if (!total_pages || total_pages <= 1) return null;

    const renderPageButton = (pageNum) => (
      <motion.button
        key={pageNum}
        className={`pagination-button ${pageNum === current_page ? 'active' : ''}`}
        onClick={() => handlePageChange(pageNum)}
        whileTap={{ scale: 0.95 }}
        disabled={isFetching}
      >
        {pageNum}
      </motion.button>
    );

    const renderEllipsis = (key) => (
      <span key={key} className="pagination-ellipsis">...</span>
    );

    let buttons = [];
    
    // Always show first page
    buttons.push(renderPageButton(1));

    if (total_pages <= 7) {
      // If 7 or fewer pages, show all
      for (let i = 2; i < total_pages; i++) {
        buttons.push(renderPageButton(i));
      }
    } else {
      // Calculate the range of pages to show around current page
      const leftBound = Math.max(2, current_page - 2);
      const rightBound = Math.min(total_pages - 1, current_page + 2);

      // Show ellipsis after first page only if leftBound > 2
      if (leftBound > 2) {
        buttons.push(renderEllipsis('start'));
      }

      // Show pages around current page
      for (let i = leftBound; i <= rightBound; i++) {
        buttons.push(renderPageButton(i));
      }

      // Show ellipsis before last page only if rightBound < total_pages - 2
      if (rightBound < total_pages - 1) {
        buttons.push(renderEllipsis('end'));
      }
    }

    // Always show last page if more than one page
    if (total_pages > 1) {
      buttons.push(renderPageButton(total_pages));
    }

    return (
      <div className="pagination">
        <motion.button
          className="pagination-button"
          onClick={() => handlePageChange(current_page - 1)}
          disabled={current_page === 1 || isFetching}
          whileTap={{ scale: 0.95 }}
        >
          Previous
        </motion.button>
        {buttons}
        <motion.button
          className="pagination-button"
          onClick={() => handlePageChange(current_page + 1)}
          disabled={current_page === total_pages || isFetching}
          whileTap={{ scale: 0.95 }}
        >
          Next
        </motion.button>
      </div>
    );
  };

  return (
    <div className="unresolved-disputes">
      <motion.button 
        className="back-button"
        whileTap={{ scale: 0.95 }}
        onClick={() => history.push(Routes.LANDING)}
      >
        <i className="fas fa-home back-icon"></i>
        Disputes Home
      </motion.button>
      
      <div className="disputes-container">
        <div className="disputes-header">
          <h2>Unresolved Disputes</h2>
          <div className="header-actions">
            <div className="disputes-count">
              {total_disputes === 1 
                ? "1 Unresolved Dispute"
                : `${total_disputes} Unresolved Disputes`
              }
            </div>
            <motion.button 
              className="header-button header-button--primary"
              whileTap={{ scale: 0.95 }}
              onClick={() => handleAssignDispute()}
            >
              Get Next Dispute
            </motion.button>
          </div>
        </div>

        <div className="disputes-table">
          <div className="disputes-table-header">
            <div className="col-id">ID</div>
            <div className="col-name">Customer Name</div>
            <div className="col-status">Status</div>
            <div className="col-due-date">Due Date</div>
            <div className="col-actions">Actions</div>
          </div>

          <div 
            ref={tableBodyRef}
            className={`disputes-table-body ${isFetching ? 'loading' : ''}`}
          >
            {disputes.map((dispute) => (
              <div key={dispute.id} className="dispute-row">
                <div className="col-id">{dispute.id}</div>
                <div className="col-name" title={formatName(dispute.first_name, dispute.last_name)}>
                  {formatName(dispute.first_name, dispute.last_name)}
                </div>
                <div className="col-status">
                  {dispute.failed_responses_count > 0 && (
                    <div 
                      className="error-pill" 
                      title={dispute.latest_failed_response_error}
                    >
                      {dispute.failed_responses_count} Failed Attempt(s)
                    </div>
                  )}
                </div>
                <div className="col-due-date">
                  {formatYearFirstDashDate(dispute.due_date)}
                </div>
                <div className="col-actions">
                  <motion.button 
                    className="view-dispute-button"
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleAssignDispute(dispute.id)}
                  >
                    View Details
                  </motion.button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {renderPagination()}
      </div>
    </div>
  );
};

export default Unresolved;
