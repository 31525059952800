import { useState, useRef, useCallback } from 'react';

const useTimeout = ({
  timeoutDuration,
  warningDuration,
  onTimeout,
  onWarning,
  isEnabled = true
}) => {
  const [showWarning, setShowWarning] = useState(false);
  const remainingSeconds = warningDuration / 1000;
  const timeoutRef = useRef(null);
  const warningRef = useRef(null);

  const startTimer = useCallback(() => {
    if (!isEnabled) return;

    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (warningRef.current) clearTimeout(warningRef.current);

    warningRef.current = setTimeout(() => {
      setShowWarning(true);
      if (onWarning) onWarning();
    }, timeoutDuration - warningDuration);

    timeoutRef.current = setTimeout(async () => {
      await onTimeout();
      setShowWarning(false);
    }, timeoutDuration);
  }, [timeoutDuration, warningDuration, onTimeout, onWarning, isEnabled]);

  const resetTimer = useCallback(() => {
    setShowWarning(false);
    startTimer();
  }, [startTimer]);

  const cleanup = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (warningRef.current) clearTimeout(warningRef.current);
  }, []);

  return {
    showWarning,
    remainingSeconds,
    startTimer,
    resetTimer,
    cleanup
  };
};

export default useTimeout; 