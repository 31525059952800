export const DISPUTE_STATUS = Object.freeze({
  UNRESOLVED: 'unresolved',
  ASSIGNED: 'assigned',
  PROCESSING: 'processing',
  RESOLVED: 'resolved'
});

export const DISPUTE_TYPES = Object.freeze({
  DEBT_VALIDATION: {
    key: 'DEBT_VALIDATION',
    display: 'Debt Validation',
    api: 'Dispute::DebtValidation'
  },
  IDENTITY_THEFT: {
    key: 'IDENTITY_THEFT',
    display: 'Identity Theft',
    api: 'Dispute::IdentityTheft'
  },
  GOODWILL_REQUEST: {
    key: 'GOODWILL_REQUEST',
    display: 'Goodwill Request',
    api: 'Dispute::GoodwillRequest'
  },
  OTHER: {
    key: 'OTHER',
    display: 'Other',
    api: 'Dispute::Other'
  }
});

export const RESPONSE_TYPES = Object.freeze({
  DEBT_VALIDATION_RESPONSE: {
    key: 'DEBT_VALIDATION_RESPONSE',
    display: 'Debt Validation',
    api: 'validation',
    requiredFields: ['user_id', 'loan_id','first_name', 'last_name', 'street', 'city', 'state', 'zip', 'type']
  },
  IDENTITY_THEFT_RESPONSE: {
    key: 'IDENTITY_THEFT_RESPONSE',
    display: 'Identity Theft',
    api: 'identity',
    requiredFields: ['user_id', 'loan_id', 'first_name', 'last_name', 'street', 'city', 'state', 'zip', 'type']
  },
  CANNOT_DELETE_RESPONSE: {
    key: 'CANNOT_DELETE_RESPONSE',
    display: 'Cannot Delete',
    api: 'cannot_delete',
    requiredFields: ['user_id', 'loan_id', 'first_name', 'last_name', 'street', 'city', 'state', 'zip', 'type']
  },
  NOT_FOUND_RESPONSE: {
    key: 'NOT_FOUND_RESPONSE',
    display: 'Not Found',
    api: 'not_found',
    requiredFields: ['first_name', 'last_name', 'street', 'city', 'state', 'zip', 'type']
  },
  INVALID_THIRD_PARTY_RESPONSE: {
    key: 'INVALID_THIRD_PARTY_RESPONSE',
    display: 'Invalid Third Party',
    api: '3pa',
    requiredFields: ['user_id', 'first_name', 'last_name', 'type']
  },
  INVALID_POA_RESPONSE: {
    key: 'INVALID_POA_RESPONSE',
    display: 'Invalid POA',
    api: 'poa',
    requiredFields: ['user_id', 'first_name', 'last_name', 'type']
  },
  NO_RESPONSE: {
    key: 'NO_RESPONSE',
    display: 'Resolve with no response',
    api: 'no_response',
    requiredFields: []
  }
});

// Helper functions to convert between formats
export const getDisputeDisplayFromApi = (apiType) => {
  return Object.values(DISPUTE_TYPES).find(type => type.api === apiType)?.display;
};

export const getDisputeApiFromDisplay = (displayType) => {
  return Object.values(DISPUTE_TYPES).find(type => type.display === displayType)?.api;
};

export const getResponseDisplayFromApi = (apiType) => {
  return Object.values(RESPONSE_TYPES).find(type => type.api === apiType)?.display;
};

export const getResponseApiFromDisplay = (displayType) => {
  return Object.values(RESPONSE_TYPES).find(type => type.display === displayType)?.api;
};
