// creates the base api service for internal
// feature-specific endpoints should be injected into this service: https://redux-toolkit.js.org/rtk-query/usage/code-splitting
// createApi should likely NOT be used again anywhere in the app
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { domain } from "../helpers/route_helper";
import { SESSION_COOKIE, readCookie } from "../helpers/cookie_helper";

// initialize an empty base api service; endpoints can be injected as needed via injectEndpoints
export const internalApi = createApi({
  baseQuery: fetchBaseQuery({ 
    baseUrl: `${domain}/internal/api/`,
    headers: {Authorization: 'Bearer ' + readCookie(SESSION_COOKIE.API_TOKEN)},
  }),
  endpoints: () => ({}),
  tagTypes: ['Dispute', 'Disputes'],
})