import React from 'react';

export const TabPanel = ({ children, value, index }) => {
  return value === index ? <div className="tab-panel">{children}</div> : null;
};

export const Tabs = ({ children, value, onChange }) => {
  return (
    <div className="tabs">
      <div className="tab-list">
        {React.Children.map(children, (child, index) => {
          if (child.type === Tab) {
            return React.cloneElement(child, {
              selected: value === index,
              onClick: () => onChange(index)
            });
          }
          return child;
        })}
      </div>
    </div>
  );
};

export const Tab = ({ label, selected, onClick, disabled }) => {
  return (
    <button 
      className={`tab ${selected && 'selected'} ${disabled && 'disabled'}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
}; 