/**
 * Validates a US ZIP code in either XXXXX or XXXXX-XXXX format
 * @param {string} zip - The ZIP code to validate
 * @returns {boolean} - Whether the ZIP code is valid
 */
export const validateZip = (zip) => {
  const zipRegex = /^\d{5}(-\d{4})?$/;
  return zipRegex.test(zip);
};

/**
 * Formats a ZIP code input value to ensure correct format
 * @param {string} value - The input value to format
 * @returns {string} - The formatted ZIP code
 */
export const formatZipInput = (value) => {
  const cleanValue = value.replace(/[^\d]/g, '');
  let pre_hyphen = cleanValue.slice(0, 5);
  let post_hyphen = cleanValue.slice(5, 9);
  return `${pre_hyphen}${post_hyphen && ("-" + post_hyphen)}`;
};

/**
 * Validates a phone number in (XXX) XXX-XXXX format
 * @param {string} phone - The phone number to validate
 * @returns {boolean} - Whether the phone number is valid
 */
export const validatePhone = (phone) => {
  const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
  return phoneRegex.test(phone);
};

/**
 * Formats a phone number input value to ensure (XXX) XXX-XXXX format
 * @param {string} value - The input value to format
 * @returns {string} - The formatted phone number
 */
export const formatPhoneInput = (value) => {
  const cleanValue = stripPhoneFormat(value);
  if (cleanValue.length === 0) return '';
  
  const areaCode = cleanValue.slice(0, 3);
  const middle = cleanValue.slice(3, 6);
  const last = cleanValue.slice(6, 10);

let fmtVal  = `(${areaCode}`;
if (middle) fmtVal += `) ${middle}`;
if (last) fmtVal += `-${last}`;

return fmtVal;
};

/**
 * Validates a full SSN in XXX-XX-XXXX format
 * @param {string} ssn - The SSN to validate
 * @returns {boolean} - Whether the SSN is valid
 */
export const validateSSN = (ssn) => {
  const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;
  return ssnRegex.test(ssn);
};

/**
 * Formats an SSN input value to ensure XXX-XX-XXXX format
 * @param {string} value - The input value to format
 * @returns {string} - The formatted SSN
 */
export const formatSSNInput = (value) => {
  const cleanValue = stripSSNFormat(value);
  const parts = [
    cleanValue.slice(0, 3),
    cleanValue.slice(3, 5),
    cleanValue.slice(5, 9)
  ];
  return parts.filter(Boolean).join('-');
};

/**
 * Validates a partial (last 4) SSN
 * @param {string} ssn - The partial SSN to validate
 * @returns {boolean} - Whether the partial SSN is valid
 */
export const validatePartialSSN = (ssn) => {
  const partialSSNRegex = /^\d{4}$/;
  return partialSSNRegex.test(ssn);
};

/**
 * Formats a partial SSN input value to ensure XXXX format
 * @param {string} value - The input value to format
 * @returns {string} - The formatted partial SSN
 */
export const formatPartialSSNInput = (value) => {
  const cleanValue = value.replace(/[^\d]/g, '');
  return cleanValue.slice(0, 4);
};

/**
 * Validates a date of birth in MM/DD/YYYY format
 * @param {string} dob - The date of birth to validate
 * @returns {boolean} - Whether the date of birth is valid
 */
export const validateDOB = (dob) => {
  const dobRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
  return dobRegex.test(dob);
};

/**
 * Formats a date of birth input value to ensure MM/DD/YYYY format
 * @param {string} value - The input value to format
 * @returns {string} - The formatted date of birth
 */
export const formatDOBInput = (value) => {
  const cleanValue = value.replace(/[^\d]/g, '');
  const parts = [
    cleanValue.slice(0, 2),
    cleanValue.slice(2, 4),
    cleanValue.slice(4, 8)
  ];
  return parts.filter(Boolean).join('/');
};

export const US_STATES = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 
  'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 
  'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 
  'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 
  'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 
  'WY'
];

/**
 * Strips formatting from a phone number, leaving only digits
 * @param {string} phone - The formatted phone number
 * @returns {string} - Just the digits
 */
export const stripPhoneFormat = (phone) => {
  return phone ? phone.replace(/[^\d]/g, '') : '';
};

/**
 * Strips formatting from a full SSN, leaving only digits
 * @param {string} ssn - The formatted SSN
 * @returns {string} - Just the digits
 */
export const stripSSNFormat = (ssn) => {
  return ssn ? ssn.replace(/[^\d]/g, '') : '';
}; 